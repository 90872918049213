import { createContext, useState, ReactNode, FC } from "react";

export const SubscribeModalContext = createContext(
  {} as {
    open: boolean;
    setOpen: (open: boolean) => void;
  }
);

type Props = {
  children: ReactNode;
};

export const SubscribeModalProvider: FC<Props> = (props) => {
  const [open, setOpen] = useState<boolean>(false);

  const { children } = props;

  return (
    <SubscribeModalContext.Provider value={{ open, setOpen }}>
      {children}
    </SubscribeModalContext.Provider>
  );
};
