import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";

import Grid from "@mui/material/Grid";
import { useState, useEffect, useContext } from "react";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import liff from "@line/liff";
import axios from "axios";
import { SubscribeModalContext } from "../../providers/SubscribeModalProvider";

export default function MediaControlCard() {
  const [userId, setUserId] = useState<string | null>(null);
  const [wordResponse, setWordResponse] = useState<any | null>({
    Item: { words: [] },
  });
  const [speakerOpacity, setSpeakerOpacity] = useState<number>(1);
  const [trashOpacity, setTrashOpacity] = useState<number>(1);

  const { open, setOpen } = useContext(SubscribeModalContext);

  useEffect(() => {
    const fetchWordData = async () => {
      try {
        const profile = await liff.getProfile();
        const userId = profile.userId;
        setUserId(userId);
        const response = await axios.post("/v1/api/word/fetch", {
          userId: profile.userId,
        });

        setWordResponse(response.data);

        // expire_dateがnullか、expire_dateが現在日付より前の場合はサブスク申請用のモーダルを表示する
        if (
          !response.data.Item.expire_date ||
          new Date(response.data.Item.expire_date) < new Date()
        ) {
          setOpen(true);
        }
      } catch (error) {
        console.error("Error fetching word data:", error);
      }
    };

    fetchWordData();
  }, []);

  const speakText = (word: string) => {
    const instance = new SpeechSynthesisUtterance(word);
    instance.lang = wordResponse.Item.studying_language;
    window.speechSynthesis.speak(instance);
  };

  const deleteText = (word: string, index: string) => {
    axios
      .post("/v1/api/word/delete", {
        userId: userId,
        word: word,
      })
      .then(() => {
        const block = document.getElementById(word + "_" + index);
        block?.remove();
      })
      .catch((error) => {
        console.error("Error deleting word:", error);
      });
  };

  return (
    <div style={{ minHeight: "100vh" }}>
      {(() => {
        const array = [];
        for (let i = 0; i < wordResponse.Item.words.length; i++) {
          array.push(
            <Card
              id={wordResponse.Item.words[i].word + "_" + i}
              sx={{
                maxWidth: "80%",
                margin: "0 auto",
                my: 5,
                display: "flex",
                p: 3,
                wordWrap: "break-word",
                fontSize: 5,
              }}
              key={i}
            >
              <div>
                <CampaignOutlinedIcon
                  sx={{ opacity: speakerOpacity }}
                  onClick={() => speakText(wordResponse.Item.words[i].word)}
                  onMouseEnter={() => setSpeakerOpacity(0.5)}
                  onMouseLeave={() => setSpeakerOpacity(1)}
                />
                <Grid container sx={{ color: "red", marginTop: 1 }}>
                  <Grid item xs={8}>
                    <DeleteIcon
                      sx={{ opacity: trashOpacity }}
                      onClick={() =>
                        deleteText(
                          wordResponse.Item.words[i].word,
                          i.toString()
                        )
                      }
                      onMouseEnter={() => setTrashOpacity(0.5)}
                      onMouseLeave={() => setTrashOpacity(1)}
                    />
                  </Grid>
                </Grid>
              </div>
              <Box sx={{ width: "35%" }}>
                <CardContent>
                  <Typography
                    component="div"
                    variant="h6"
                    sx={{
                      fontSize: 14,
                    }}
                  >
                    {wordResponse.Item.words[i].word}
                  </Typography>
                </CardContent>
              </Box>
              <Box sx={{ width: "35%" }}>
                <CardContent>
                  <Typography
                    component="div"
                    variant="h6"
                    sx={{
                      fontSize: 14,
                    }}
                  >
                    {wordResponse.Item.words[i].translated}
                  </Typography>
                </CardContent>
              </Box>
              <Box>
                <CardMedia
                  component="img"
                  sx={{
                    height: "70px",
                    ml: "auto",
                    objectFit: "contain",
                  }}
                  image={wordResponse.Item.words[i].image_url}
                  alt="word-image"
                />
              </Box>
            </Card>
          );
        }
        return array;
      })()}
    </div>
  );
}
