import * as React from "react";
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Button from "@mui/material/Button";
import axios from "axios";
import liff from "@line/liff";
import Typography from "@mui/material/Typography";

function App() {
  const [motherLanguage, setMotherLanguage] = React.useState("");
  const [studyingLanguage, setStudyingLanguage] = React.useState("");
  const [expireDate, setExpireDate] = React.useState("");

  const [disabled, setDisabled] = React.useState(true);

  const handleMotherLanguageChange = (event: SelectChangeEvent) => {
    setMotherLanguage(event.target.value as string);
    setDisabled(false);
  };
  const handleStudyingLanguageChange = (event: SelectChangeEvent) => {
    setStudyingLanguage(event.target.value as string);
    setDisabled(false);
  };

  const submitLanguages = async () => {
    setDisabled(true);
    const profile = await liff.getProfile();
    axios
      .post("v1/api/mypage/update", {
        motherLanguage: motherLanguage,
        studyingLanguage: studyingLanguage,
        userId: profile.userId,
      })
      .then((response) => {
        setDisabled(false);
        alert("更新しました");
      });
  };

  React.useEffect(() => {
    const fetchMypage = async () => {
      try {
        const profile = await liff.getProfile();
        const response = await axios.post("/v1/api/mypage/fetch", {
          userId: profile.userId,
        });

        setMotherLanguage(response.data.Items[0].mother_language);
        setStudyingLanguage(response.data.Items[0].studying_language);
        setExpireDate(response.data.Items[0].expire_date);
      } catch (error) {
        console.error("Error set Languages:", error);
      }
    };

    fetchMypage();
  }, []);

  return (
    <div className="App">
      <div>
        <header
          className="App-header"
          style={{ width: "100%", position: "sticky", top: "0" }}
        >
          <Header />
        </header>
        <div>
          <h2 style={{ textAlign: "center" }}>Mypage</h2>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: 350 },
              textAlign: "center",
            }}
            noValidate
            autoComplete="off"
          >
            <div style={{ width: "80%", margin: "0 auto" }}>
              <Typography
                component="div"
                variant="h6"
                sx={{
                  fontSize: 24,
                }}
              >
                契約終了日： {expireDate}
              </Typography>

              <FormControl style={{ marginTop: "20px" }} fullWidth>
                <InputLabel id="mother-language-id">
                  母国語 (Mother Tongue)
                </InputLabel>
                <Select
                  labelId="mother-language-id"
                  id="mother-language"
                  value={motherLanguage}
                  label="Mother Tongue"
                  onChange={handleMotherLanguageChange}
                >
                  <MenuItem value={"ja"}>日本語</MenuItem>
                  <MenuItem value={"en"}>English</MenuItem>
                  <MenuItem value={"fr"}>Français</MenuItem>
                  <MenuItem value={"de"}>German</MenuItem>
                  <MenuItem value={"es"}>Spanish</MenuItem>
                  <MenuItem value={"it"}>Italian</MenuItem>
                  <MenuItem value={"ko"}>Korean</MenuItem>
                  <MenuItem value={"zh"}>Chinese</MenuItem>
                </Select>
              </FormControl>

              <FormControl style={{ marginTop: "20px" }} fullWidth>
                <InputLabel id="target-language-id">
                  勉強中の言語 (Studying Language)
                </InputLabel>
                <Select
                  labelId="target-language-id"
                  id="target-language"
                  value={studyingLanguage}
                  label="Studying Tongue"
                  onChange={handleStudyingLanguageChange}
                >
                  <MenuItem value={"en-US"}>English (American)</MenuItem>
                  <MenuItem value={"en-GB"}>English (UK)</MenuItem>
                  <MenuItem value={"en-AU"}>English (Australia)</MenuItem>
                  <MenuItem value={"fr-FR"}>Français</MenuItem>
                  <MenuItem value={"de-DE"}>German</MenuItem>
                  <MenuItem value={"es-ES"}>Spanish</MenuItem>
                  <MenuItem value={"it-IT"}>Italian</MenuItem>
                  <MenuItem value={"ko-KR"}>Korean</MenuItem>
                  <MenuItem value={"zh-CN"}>Chinese (China)</MenuItem>
                  <MenuItem value={"ja-JP"}>日本語</MenuItem>
                </Select>
              </FormControl>
            </div>
            <Button disabled={disabled} onClick={submitLanguages}>
              更新
            </Button>
          </Box>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default App;
