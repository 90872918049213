import { BrowserRouter, Route, Routes } from "react-router-dom";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Home from "./pages/Home";
import Mypage from "./pages/Mypage";
import reportWebVitals from "./reportWebVitals";
import liff from "@line/liff";
import { SubscribeModalProvider } from "./providers/SubscribeModalProvider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

liff
  .init({
    liffId: process.env.REACT_APP_LIFF_ID || "",
    withLoginOnExternalBrowser: true,
  })
  .then(() => {
    root.render(
      <React.StrictMode>
        <SubscribeModalProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/mypage" element={<Mypage />} />
            </Routes>
          </BrowserRouter>
        </SubscribeModalProvider>
      </React.StrictMode>
    );
  })
  .catch((e) => {
    alert(`LIFF error: ${e.message}`);
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
