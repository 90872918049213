import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { Elements } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import CardForm from "./CardForm";
import liff from "@line/liff";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLIC_KEY as string
);

function App() {
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    const fetchClientSecret = async () => {
      try {
        const profile = await liff.getProfile();
        const response = await axios.post("/v1/api/create_payment", {
          userId: profile.userId,
        });

        setClientSecret(response.data);
      } catch (error) {
        console.error("Error set ClientSecret:", error);
      }
    };

    fetchClientSecret();
  }, []);

  return (
    <>
      {clientSecret && stripePromise && (
        <Elements
          stripe={stripePromise}
          options={{ clientSecret: clientSecret }}
        >
          <CardForm />
        </Elements>
      )}
    </>
  );
}

export default App;
