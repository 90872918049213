import Header from "../components/common/Header";
import WordCard from "../components/home/WordCard";
import Footer from "../components/common/Footer";
import SubscribeModal from "../components/subscription/SubscribeModal";
import { useContext } from "react";
import Modal from "@mui/material/Modal";
import { SubscribeModalContext } from "../providers/SubscribeModalProvider";

function App() {
  const { open } = useContext(SubscribeModalContext);

  return (
    <div className="App">
      <div>
        <header
          className="App-header"
          style={{ width: "100%", position: "sticky", top: "0" }}
        >
          <Header />
        </header>

        <Modal
          // 課金をスタートさせるときに、open={open}へ変更する
          open={false}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div>
            <SubscribeModal />
          </div>
        </Modal>
        <div>
          <h2 style={{ textAlign: "center" }}>Word</h2>

          <WordCard />

          <Footer />
        </div>
      </div>
    </div>
  );
}

export default App;
