import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { SubscribeModalContext } from "../../providers/SubscribeModalProvider";
import { useContext } from "react";

function CardForm() {
  const { setOpen } = useContext(SubscribeModalContext);

  const stripe = useStripe();
  const elements = useElements();
  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const uri = new URL(window.location.href);

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: uri.origin + "/mypage",
      },
    });

    if (result.error) {
      console.log(result.error.message);
    } else {
      setOpen(false);
      // 更新用のAPIを叩く
    }
  };

  return (
    <Card sx={{ maxWidth: 400, margin: "0 auto", my: 5, p: 3 }}>
      <Box>
        <CardContent>
          <form onSubmit={handleSubmit}>
            <PaymentElement />
            <Button
              disabled={!stripe}
              style={{ marginTop: 15 }}
              variant="contained"
              type="submit"
            >
              支払い
            </Button>
          </form>
        </CardContent>
      </Box>
    </Card>
  );
}

export default CardForm;
